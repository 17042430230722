import React from 'react';
import { GitHub, Gitlab, Instagram, Twitter } from 'react-feather';
import {
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

const styles = StyleSheet.create({
  app: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 24,
    letterSpacing: 1.2,
  },
  button: {
    backgroundColor: 'hsl(206, 100%, 50%)',
    padding: 8,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  linksView: {
    flexDirection: 'row',
    marginVertical: 10,
    minHeight: 40,
  },
});

export function App() {
  // Hooks
  const { height, width } = useWindowDimensions();

  return (
    <View style={[styles.app, { height, width }]}>
      <Text style={styles.text}>Griffin Sockwell</Text>

      <View style={styles.linksView}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => Linking.openURL('https://twitter.com/griffinsockwell')}
        >
          <Twitter color="#fff" />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.button}
          onPress={() =>
            Linking.openURL('https://www.instagram.com/griffinsockwell/')
          }
        >
          <Instagram color="#fff" />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.button}
          onPress={() => Linking.openURL('https://github.com/griffinsockwell')}
        >
          <GitHub color="#fff" />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.button}
          onPress={() => Linking.openURL('https://gitlab.com/griffinsockwell')}
        >
          <Gitlab color="#fff" />
        </TouchableOpacity>
      </View>
    </View>
  );
}
